<template>
  <div class="upsert-warranty-bill">
    <KTCodePreview v-bind:title="''">
      <template v-slot:preview>
        <!-- navigate -->
        <b-card no-body class="full-width">
          <div
            class="wizard wizard-4"
            id="kt_wizard_v4"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Nav-->
            <div class="wizard-nav" style="background-color: #eef0f8">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper">
                    <div class="wizard-label">
                      <div class="wizard-title">Thông tin</div>
                    </div>
                  </div>
                </div>
                <div class="wizard-step ml-1" data-wizard-type="step">
                  <div class="wizard-wrapper">
                    <div class="wizard-label">
                      <div class="wizard-title">Tệp đính kèm</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- tab main content -->
            <div
              class="main-content"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <b-form ref="form" lazy-validation class="row">
                <!-- Main content -->
                <b-container class="bv-example-row">
                  <b-row class="main-info">
                    <!-- Left side content -->
                    <b-col md="9">
                      <p class="text-list-product">Thông tin sản phẩm</p>
                      <!-- product table -->
                      <b-row
                        class="mb-2"
                        v-if="warrantyErrorBy === WARRANTY_ERROR_BY.STORE"
                      >
                        <b-col cols="3">
                          <b-form-select
                            class="select-style"
                            v-model="selectTypeSearch"
                            :options="listTypeSearch"
                            size="sm"
                            value-field="id"
                            text-field="name"
                          ></b-form-select>
                        </b-col>
                        <b-col cols="9" class="pl-0">
                          <AutoSuggest
                            :model="searchProduct"
                            :suggestions="filteredOptionsProduct"
                            @select="onSelectedProduct"
                            @change="onInputSearchProduct"
                            placeholder="tên sản phẩm"
                            :limit="10"
                          >
                            <template #custom="{ suggestion }">
                              <div>
                                <div class="d-flex flex-column">
                                  <span class="text-primary mb-1">{{
                                    suggestion.item.productName
                                  }}</span>
                                </div>
                              </div>
                            </template>
                          </AutoSuggest>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <table
                            class="table table-bordered table-vertical-center table-hover"
                          >
                            <thead>
                              <tr>
                                <th class="header-table text-center">STT</th>
                                <th class="header-table">Sản phẩm</th>
                                <th class="header-table column-quantity">SL</th>
                                <th class="header-table">Giá</th>
                                <th class="header-table">Thành tiền</th>
                                <th class="header-table">Lý do bảo hành</th>
                                <th
                                  class="header-table column-import-stock"
                                  v-if="
                                    warrantyErrorBy !== WARRANTY_ERROR_BY.STORE
                                  "
                                >
                                  Mất xác
                                </th>
                                <th
                                  class="header-table column-swap-product"
                                  v-if="
                                    checkWarrantyStatus !==
                                      WARRANTY_BILL_STATUS.DONE &&
                                    warrantyErrorBy ===
                                      WARRANTY_ERROR_BY.BILL &&
                                    action !== 'create' &&
                                    action !== 're-create'
                                  "
                                >
                                  Đổi sp
                                </th>
                                <th
                                  v-show="
                                    action === 'create' ||
                                    action === 're-create'
                                  "
                                ></th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="item in listBillItem"
                              :key="item.id + item.productId"
                            >
                              <WarrantyBillItem
                                :warrantyErrorBy="warrantyErrorBy"
                                :productItem="item"
                                :action="action"
                                :warrantyReasons="filterReasonByCate(item)"
                                :checkWarrantyStatus="checkWarrantyStatus"
                                :mainModel="mainModel"
                                @deleteBillItem="deleteBillItem"
                                @onSelectWarranty="onSelectWarranty"
                                @onInputWarrantyReason="onInputWarrantyReason"
                                @onChangeProduct="onChangeProduct"
                                @linkToListImei="linkToListImei"
                                ref="billItemRef"
                              />
                            </tbody>
                            <tfoot></tfoot>
                          </table>
                        </b-col>
                      </b-row>
                      <!-- user information -->
                      <b-row></b-row>

                      <b-row>
                        <b-col md="4">
                          <b-form-group>
                            <label
                              >Điện thoại<b class="text-danger">*</b>
                            </label>
                            <AutoSuggest
                              :model="mainModel.customerPhone"
                              :suggestions="filteredOptionsCustomer"
                              :suggestionName="'phoneNo'"
                              placeholder="số điện thoại"
                              :limit="10"
                              @select="onSelectCustomer"
                              @change="onSearchChange"
                            >
                              <template #custom="{ suggestion }">
                                <div>
                                  <div class="d-flex flex-column">
                                    <span
                                      class="text-primary mb-1"
                                      v-if="suggestion.item.phoneNo"
                                      >({{ suggestion.item.phoneNo }})</span
                                    >
                                    <span v-if="suggestion.item.fullName">{{
                                      suggestion.item.fullName
                                    }}</span>
                                    <span v-else>{{
                                      suggestion.item.message
                                    }}</span>
                                  </div>
                                </div>
                              </template>
                            </AutoSuggest>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label
                              >Nhân viên bảo hành<b class="text-danger"
                                >*</b
                              ></label
                            >
                            <AutoSuggest
                              :suggestions="filteredEmployeeOptions"
                              :model="mainModel.employeeName"
                              :placeholder="'nhân viên bảo hành'"
                              :limit="10"
                              @change="onInputEmployee"
                              @select="onSelectEmployee"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="4">
                          <b-form-group>
                            <label
                              >Họ tên khách hàng<b class="text-danger"
                                >*</b
                              ></label
                            >
                            <b-form-input
                              class="input-style"
                              size="sm"
                              type="text"
                              v-model="mainModel.customerName"
                              placeholder="Họ tên khách hàng"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="4" v-if="isFeatureActive('get-otp-from-loyalty')">
                          <b-form-group v-if="
                              action === 'update' &&
                              isChangeProduct &&
                              !(
                                mainModel.isReCreateBillDone &&
                                checkPermission('WARRANTY_BILL_DONE_RECREATE')
                              )
                            ">
                            <b-row class="align-items-center justify-content-center">
                              <b-col md="6">
                                <label>Nhập mã OTP:</label>
                              </b-col>
                              <b-col
                                md="6"
                                class="text-right"
                              >
                                <a
                                  href="javascript:void(0)"
                                  style="color: blue; font-weight: bold"
                                  class=" my-0"
                                  @click="sendOTP()"
                                >Gửi mã</a>
                              </b-col>
                            </b-row>
                            <b-input-group>
                              <b-form-input
                                class="input-style"
                                size="sm"
                                type="text"
                                v-model="customerOTP"
                                placeholder="Nhập mã OTP"
                              ></b-form-input>
                              <b-input-group-append>
                                <b-button
                                  style="width: 40px"
                                  variant="secondary"
                                  size="sm"
                                  @click="checkOTP()"
                                >
                                  <i class="fas fa-sync"></i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <b-col md="4" v-else>
                          <b-form-group
                            v-if="
                              action === 'update' &&
                              isChangeProduct &&
                              !(
                                mainModel.isReCreateBillDone &&
                                checkPermission('WARRANTY_BILL_DONE_RECREATE')
                              )
                            "
                          >
                            <label
                              >Nhập mã OTP<b class="text-danger">*</b></label
                            >
                            <b-input-group>
                              <b-form-input
                                class="input-style"
                                size="sm"
                                type="text"
                                v-model="customerOTP"
                                placeholder="Nhập mã OTP"
                              ></b-form-input>

                              <b-input-group-append>
                                <b-button
                                  style="width: 40px"
                                  variant="secondary"
                                  size="sm"
                                  @click="sendOTP()"
                                >
                                  <i class="fas fa-mobile-alt"></i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="4">
                          <b-form-group>
                            <label>Địa chỉ</label>
                            <b-form-input
                              class="input-style"
                              size="sm"
                              type="text"
                              v-model="mainModel.customerAddress"
                              placeholder="Địa chỉ khách hàng"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group :disabled="isDisableStatus">
                            <label>Trạng thái bảo hành:</label>
                            <b-form-select
                              :options="
                                newStatusOnly
                                  ? optionWarrantyStatus.filter(
                                      ({ id }) =>
                                        id === WARRANTY_BILL_STATUS.NEW,
                                    )
                                  : optionWarrantyStatus
                              "
                              class="select-style"
                              size="sm"
                              v-model="mainModel.warrantyStatus"
                              value-field="id"
                              text-field="name"
                              placeholder="Trạng thái bảo hành"
                            ></b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="4">
                          <b-form-group>
                            <label>Ghi chú bảo hành:</label>
                            <b-form-textarea
                              class="input-style"
                              size="sm"
                              type="text"
                              v-model="mainModel.warrantyNote"
                              placeholder="Ghi chú bảo hành"
                              :rows="6"
                              :max-rows="6"
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label>Ghi chú nội bộ</label>
                            <b-form-textarea
                              class="input-style"
                              size="sm"
                              type="text"
                              v-model="mainModel.internalNote"
                              placeholder="Ghi chú nội bộ"
                              :rows="6"
                              :max-rows="6"
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <!-- Right side content -->
                    <b-col md="3">
                      <p class="text-payment">Thanh toán</p>
                      <b-form-group>
                        <label>Cửa hàng:</label>
                        <AutoSuggest
                          :suggestions="filteredStoreOptions"
                          :model="mainModel.storeName"
                          :placeholder="'cửa hàng'"
                          :limit="10"
                          @change="onInputStore"
                          @select="onSelectStore"
                        />
                      </b-form-group>
                      <div v-if="renderComponent">
                        <BillPayment
                          :billData="mainModel"
                          :excludes="excludes"
                          v-on:receive-payment-mul="onUpdatePaymentV2"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </b-form>
            </div>
            <!-- tab attach image -->
            <div class="attach-image" data-wizard-type="step-content">
              <UploadFile
                v-if="renderComponent"
                :id="billNumber"
                :entity="'bills'"
              />
            </div>
          </div>
        </b-card>
      </template>
      <!-- page footer -->
      <template v-slot:foot>
        <b-container class="bv-example-row">
          <b-row>
            <b-button
              tabindex="15"
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              :disabled="onLoading"
              @click="debounceSubmit"
              >Lưu</b-button
            >
            <b-button
              tabindex="16"
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="onClickCancel"
              >Hủy</b-button
            >
          </b-row>
        </b-container>

        <ChangeWarrantyProduct
          @changeWarrantyProduct="changeWarrantyProduct"
          @linkToListImei="linkToListImei"
        />
        <ProductImeiModal
          :title="modalImeiTitle"
          :storeName="mainModel.storeName"
          :listProductImei="listImeiByProduct"
        />
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import KTCodePreview from '@/view/content/CodePreview.vue';
import WarrantyBillItem from '@/view/components/bills/WarrantyBillItem.vue';
import ChangeWarrantyProduct from '@/view/components/bills/ChangeWarrantyProduct.vue';
import ProductImeiModal from '@/view/components/bills/ProductImeiModal.vue';
import ApiService from '@/core/services/api.service';
import {
  PRODUCT_TYPE,
  WARRANTY_BILL_STATUS,
  BILL_ITEM_TYPE,
  PAYMENT_TYPE,
  WARRANTY_ERROR_BY,
} from '@/utils/enum';
import cloneDeep from 'lodash/cloneDeep';
import AutoSuggest from '@/view/base/auto-sugguest/AutoSuggest';
import {
  isEmptyAccountingAccount,
  removeAccents,
  convertPrice,
  makeToastFaile,
  makeToastSuccess,
  currencyMask,
  unMaskPrice,
} from '@/utils/common';
import { debounce } from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import { EventBus } from '@/core/services/event-bus';
import { getData } from '@/utils/saveDataToLocal';
import { v4 as uuidv4 } from 'uuid';
import KTWizard from '@/assets/js/components/wizard';
import UploadFile from '@/view/modules/upload/Upload';
import { mapState, mapActions } from 'vuex';
import BillPayment from '@/view/components/bills/BillPayment.vue';
import { checkPermission } from '@/utils/saveDataToLocal';
import { STATUS_CODE } from '@/utils/enum';
import { featureFlagMixin } from '@/view/mixins';

export default {
  mixins: [featureFlagMixin],
  components: {
    KTCodePreview,
    WarrantyBillItem,
    ChangeWarrantyProduct,
    AutoSuggest,
    UploadFile,
    ProductImeiModal,
    BillPayment,
  },
  computed: {
    ...mapState({
      exchangeProduct: (state) => state.exchangeProduct.exchangeProduct,
    }),
    pageTitle() {
      return `Hóa đơn bảo hành${
        this.action === 'update' ? ` #${this.billNumber}` : ''
      }`;
    },
    isDisableStatus() {
      return (
        this.checkWarrantyStatus === WARRANTY_BILL_STATUS.DONE ||
        (this.checkWarrantyStatus === WARRANTY_BILL_STATUS.NEW &&
          ['create', 're-create'].includes(this.action))
      );
    },
  },
  data() {
    return {
      excludes: {
        fields: ['excessCash'],
        paymentMethods: [PAYMENT_TYPE.INSTALLMENT, PAYMENT_TYPE.REFUND],
      },
      excludePaymentMethods: [PAYMENT_TYPE.INSTALLMENT, PAYMENT_TYPE.REFUND],
      renderComponent: true,
      WARRANTY_ERROR_BY,
      selectTypeSearch: 1,
      customerOTP: null,
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
      ],
      optionsProduct: [],
      filteredOptionsProduct: [],
      searchProduct: '',
      optionsCustomer: [],
      filteredOptionsCustomer: [],
      onLoading: false,
      fieldListImei: [
        {
          key: 'imeiCode',
          label: 'IMEI',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        { key: 'actions', label: '' },
      ],
      modalImeiTitle: '',
      WARRANTY_BILL_STATUS,
      mask: currencyMask,
      billNumber: null,
      billNumberRemove: null,
      billId: null,
      action: '',
      checkWarrantyStatus: 1,
      mainModel: {
        employeeStore: '',
        storeId: null,
        cashAmount: 0,
        cashId: null,
        cashAccountId: null,
        cashAccountName: '',
        cashAccountCode: null,
        transferAmount: 0,
        transferId: null,
        transferAccountName: '',
        transferAccountId: null,
        transferAccountCode: null,
        customerId: '',
        customerName: '',
        customerBankName: '',
        customerTransferNo: '',
        employeeName: '',
        customerPhone: '',
        customerAddress: '',
        warrantyNote: '',
        warrantyStatus: 1,
        internalNote: '',
        saleId: null,
        accessoryStaffId: null,
        accessoryStaffName: '',
        assistantId: null,
        assistantName: '',
        cashierId: null,
        cashierName: '',
        codeShip: '',
        companyId: null,
        companyName: '',
        contractCode: null,
        contractDate: null,
        orderId: null,
        billType: null,
        relateBill: {
          billNumber: null,
        },
        paymentInfo: {
          //tiền mặt
          cashAmount: 0,
          cashAccountantName: '',
          cashAccountId: null,
          //chuyển khoản
          transferAmount: 0,
          transferAccountantName: '',
          transferAccountantId: null,
          transferReferenceCode: '',
          //quẹt thẻ
          creditAmount: 0,
          creditAccountantName: '',
          creditAccountantId: null,
          creditCode: '',
          creditCardNo: '',
          //phí quẹt thẻ
          creditCardFee: 0,
          creditFeeAccountantName: '',
          creditFeeAccountantId: null,
          payCreditFeeType: 1,
          //trả góp
          installedMoneyAmount: 0,
          installmentCode: '',
          installmentAccountName: '',
          installMoneyAccountId: null,
          customerIndentifyNo: '',
          installmentProgramMonthNo: 0,
        },
        installments: [],
        credits: [],
        transfer: [],
        cash: [],
        refunds: [],
        isReCreateBillDone: false,
      },
      warrantyReasons: [],
      listWarrantyStatus: [
        {
          id: null,
          name: 'Chọn trạng thái',
          disabled: true,
        },
        {
          id: 1,
          name: 'Mới',
          disabled: this.isAllowToUpdateStatus,
        },
        {
          id: 2,
          name: 'Đang xử lý',
          disabled: this.isAllowToUpdateStatus,
        },
        {
          id: 3,
          name: 'Đã trả bảo hành',
        },
      ],
      listBillItem: [],
      isChangeProduct: false,
      selectedCashAccount: '',
      filteredCashAccOptions: [],
      cashAccOptions: [],
      filteredTransferOptions: [],
      transferOptions: [],
      listStatus: [],
      optionValue: {
        new: 1,
        processing: 2,
        done: 3,
      },
      listImeiByProduct: [],
      applyImeiForItemId: null,
      selectingProduct: {},
      isNew: true,
      storeOptions: [],
      listStore: [],
      filteredStoreOptions: [],
      errorMessage: '',
      listEmployee: [],
      employeeOptions: [],
      filteredEmployeeOptions: [],
      warrantyErrorBy: WARRANTY_ERROR_BY.BILL,
      optionWarrantyStatus: [
        {
          id: null,
          name: 'Chọn trạng thái',
          disabled: true,
        },
        {
          id: WARRANTY_BILL_STATUS.NEW,
          name: 'Mới',
          disabled: this.listStatus
            ? !this.listStatus.includes(this.optionValue.new)
            : false,
        },
        {
          id: WARRANTY_BILL_STATUS.PROCESSING,
          name: 'Đang xử lý',
          disabled: this.listStatus
            ? !this.listStatus.includes(this.optionValue.processing)
            : false,
        },
        {
          id: WARRANTY_BILL_STATUS.DONE,
          name: 'Đã trả bảo hành',
        },
      ],
      newStatusOnly: false,
    };
  },
  watch: {},
  created() {
    this.warrantyErrorBy =
      Number(this.$route.query.warrantyErrorBy) || WARRANTY_ERROR_BY.BILL;
    this.billNumber = this.$route.query.billId;
    this.billNumberRemove = this.$route.query.removeId;
    this.action = this.$route.query.action;

    this.fetchWarrantyReasons();
    if (this.action === 'create' || this.action === 're-create') {
      this.setEmployeeInfo();
    }
    this.fetchListStoreByUser();
    this.getListEmployee();
    this.fetchBillById();
    this.newStatusOnly =
      this.action === 'update' &&
      this.warrantyErrorBy === WARRANTY_ERROR_BY.STORE;
  },
  mounted() {
    this.wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1,
      clickableSteps: true,
    });
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn', route: '/' },
      { title: 'Danh sách hóa đơn', route: '/' },
      { title: 'Thông tin hóa đơn bảo hành', route: '/' },
    ]);
    EventBus.$on('applyImei', this.applyImei);
  },
  methods: {
    checkPermission,
    ...mapActions(['updateExchangeProduct']),
    onUpdatePaymentV2(items = [], type) {
      const payments = items.reduce((result, current) => {
        result.push({
          ...current,
          paymentAmount: unMaskPrice(current.paymentAmount),
        });
        return result;
      }, []);
      switch (type) {
        case PAYMENT_TYPE.INSTALLMENT: {
          this.mainModel.installments = payments;
          break;
        }
        case PAYMENT_TYPE.CREDIT: {
          this.mainModel.credits = payments;
          break;
        }
        case PAYMENT_TYPE.TRANSFER: {
          this.mainModel.transfer = payments;
          break;
        }
        case PAYMENT_TYPE.CASH: {
          this.mainModel.cash = payments;
          break;
        }
      }
    },
    openProductImeiModal() {
      this.$bvModal.show('list-imei-modal');
    },
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    debounceProductInput: debounce(function () {
      const textSearch = this.searchProduct ? this.searchProduct.trim() : '';
      this.fetchProduct(textSearch);
    }, TIME_TRIGGER),
    fetchProduct(textSearch) {
      ApiService.get(
        `productSearch?storeId=${this.mainModel.storeId}&searchProduct=${textSearch}`,
      ).then(({ data }) => {
        this.optionsProduct = [];
        this.filteredOptionsProduct = [];
        const products = data.data;
        products.map((element) => {
          this.optionsProduct.push(element);
        });

        this.filteredOptionsProduct = [...this.optionsProduct];
      });
    },
    sendOTP() {
      const body = {
        customerId: this.mainModel.customerId,
        phoneNumber: this.mainModel.customerPhone,
        billNumber: this.mainModel.relateBill.billNumber,
      };
      ApiService.post(`customerOTP/warranty`, body)
        .then(({ data }) => {
          makeToastSuccess(data.message);
        })
        .catch((error) => {
          if (error.response) {
            makeToastFaile(
              error.response.data ? error.response.data.message : 'Lỗi',
            );
          } else {
            makeToastFaile(error.message || 'Lỗi');
          }
        });
    },
    onSelectedProduct(option) {
      const product = option.item;

      if (product.productType === PRODUCT_TYPE.PRODUCT_COMBO) {
        return makeToastFaile('Không hỗ trợ nhập kho sản phẩm combo');
      }

      const {
        productId,
        productName,
        productCode,
        sellingPrice,
        productType,
        merchantId,
        belongToWarrantyImei,
      } = product;

      const productDTO = {
        stt: this.listBillItem.length + 1,
        id: uuidv4(),
        productId: productId,
        productName: productName,
        code: productCode,
        productPrice: sellingPrice,
        productType: BILL_ITEM_TYPE.PRODUCT,
        returnPrice: 0,
        quantity: 1,
        imeiCode: '',
        totalPrice: sellingPrice,
        originalProductType: productType,
        originalQuantity: 1,
        merchantId: merchantId || 1,
        warrantyReasonId: null,
        warrantyReasonName: '',
        belongToProductId: null,
        isGiftTaken: true,
        newId: '',
        newProductId: null,
        newProductName: '',
        newProductCode: '',
        newQuantity: 0,
        newProductPrice: 0,
        newTotalPrice: 0,
        newProductType: null,
        belongToWarrantyImei: belongToWarrantyImei || false,
      };

      this.listBillItem.push(productDTO);
      this.searchProduct = '';
    },
    onInputSearchProduct(text = '') {
      this.searchProduct = text;
      this.debounceProductInput();
    },
    getCustomerInfoById(customerId) {
      if (customerId) {
        ApiService.get(`customer/${customerId}`).then((response) => {
          const customerInfo = response.data.data;
          this.mainModel.customerName = customerInfo.fullName;
          this.mainModel.customerAddress = customerInfo.address;
        });
      }
    },
    debouncePhoneInput: debounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    getListCustomer() {
      const text = this.mainModel.customerPhone
        ? this.mainModel.customerPhone.trim()
        : null;
      if (!text) {
        return;
      }
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          this.optionsCustomer = data.data.data;
          if (this.optionsCustomer.length > 0) {
            this.filteredOptionsCustomer = [...this.optionsCustomer];
          } else {
            this.filteredOptionsCustomer = [
              { message: 'SĐT không có trong hệ thống', phoneNo: text },
            ];
          }
        },
      );
    },
    onSearchChange(searchQuery) {
      if (!searchQuery) {
        searchQuery = '';
      }
      this.mainModel.customerPhone = searchQuery;
      this.optionsCustomer = [];
      this.filteredOptionsCustomer = [...this.optionsCustomer];
      this.debouncePhoneInput();
    },
    onSelectCustomer(option) {
      this.mainModel.customerId = option.item.id;
      this.mainModel.customerPhone = option.item.phoneNo;
      this.getCustomerInfoById(option.item.id);
    },
    filterReasonByCate(product) {
      const { productWebCategory } = product;
      if (!productWebCategory) return this.warrantyReasons;

      const filteredReasons = this.warrantyReasons.filter((item) => {
        return item.internalCateId === productWebCategory;
      });

      return filteredReasons;
    },
    getListEmployee: function () {
      const storeIds = this.storeOptions.map((element) => element.id);
      ApiService.get(
        `employees/get-by-store?employeeName=&storeId=${storeIds.join(',')}`,
      ).then((data) => {
        const employees = data.data.data;
        this.listEmployee = employees;
        this.employeeOptions = employees.map((element) => {
          return {
            id: element.id,
            name: element.fullName,
          };
        });
        this.filteredEmployeeOptions = [...this.employeeOptions];
      });
    },
    fetchListStoreByUser: function () {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          if (data.data.jobTitleId === 6) {
            this.mainModel.cashierName = data.data.employeeName;
          }
          const stores = data.data.stores;
          if (stores.length === 1) {
            // this.mainModel.storeId = stores[0].id;
            // this.mainModel.storeName = stores[0].name;
            this.storeOptions = [...stores];
          } else {
            this.storeOptions = [];
            // this.mainModel.storeId = stores[0].id;
            // this.mainModel.storeName = stores[0].name;
            stores.map((element) => {
              let item = {
                id: element.id,
                suggestionName: element.name,
                name: element.name,
              };
              this.storeOptions.push(item);
            });
            this.filteredStoreOptions = [...this.storeOptions];
          }
        }
      });
    },
    onInputStore(text) {
      this.mainModel.storeName = text;
      const filteredData = this.storeOptions.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredStoreOptions = [...filteredData];
    },
    onSelectStore(option) {
      this.mainModel.storeId = option.item.id;
      this.mainModel.storeName = option.item.name;
    },
    onInputEmployee(text) {
      this.mainModel.employeeName = text;
      const filteredData = this.employeeOptions.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredEmployeeOptions = [...filteredData];
    },
    onSelectEmployee(option) {
      this.mainModel.saleId = option.item.id;
      this.mainModel.employeeName = option.item.name;
    },
    applyImei(item) {
      const { id, oldId, isMainProduct } = this.exchangeProduct;

      const updateIndex = this.listBillItem.findIndex(
        (item) => item.id === (isMainProduct ? id : oldId),
      );
      if (updateIndex === -1) return;
      const updateProduct = this.listBillItem[updateIndex];
      updateProduct[isMainProduct ? 'imeiCode' : 'newImeiCode'] = item.imeiCode;
      // update
      this.exchangeProduct.newImeiCode = item.imeiCode;
      this.updateExchangeProduct(this.exchangeProduct);
    },
    convertPrice,
    getListImei(productId) {
      if (!productId || !this.mainModel.storeId) {
        return;
      }
      let param = {
        productId: productId,
        storeId: this.mainModel.storeId,
      };
      let paramQuery = {
        params: param,
      };
      ApiService.query('imeis/get-by-product', paramQuery)
        .then(({ data }) => {
          this.listImeiByProduct = [];
          data.data.forEach((element) => {
            let item = {
              id: element.id,
              imeiCode: element.imeiNo,
              originalPrice: element.originalPrice ? element.originalPrice : 0,
              sellingPrice: element.sellingPrice ? element.sellingPrice : 0,
              status: element.status,
            };
            this.listImeiByProduct.push(item);
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    linkToListImei(product) {
      const { isMainProduct, productId, newProductId, productName } = product;
      const searchingProductId = isMainProduct ? productId : newProductId;
      this.modalImeiTitle = `Danh sách IMEI sản phẩm:  ${productName}`;
      this.updateExchangeProduct(product);
      this.getListImei(searchingProductId);
      this.openProductImeiModal();
    },
    changeWarrantyProduct(product) {
      const newProduct = cloneDeep(product);
      const updateIndex = this.listBillItem.findIndex(
        (obj) =>
          obj.id == newProduct.oldId &&
          obj.productId == newProduct.oldProductId,
      );
      if (newProduct.newProductType !== 2) {
        this.listBillItem[updateIndex].newImeiCode = '';
      }
      this.listBillItem[updateIndex].newId = newProduct.newId;
      this.listBillItem[updateIndex].newProductId = newProduct.newProductId;
      this.listBillItem[updateIndex].newProductName = newProduct.productName;
      this.listBillItem[updateIndex].newProductCode = newProduct.productCode;
      this.listBillItem[updateIndex].newQuantity = newProduct.quantity;
      this.listBillItem[updateIndex].newProductPrice = newProduct.productPrice;
      this.listBillItem[updateIndex].newTotalPrice = newProduct.totalPrice;
      this.listBillItem[updateIndex].newProductType = newProduct.newProductType;
      this.listBillItem[updateIndex].newImeiCode = newProduct.newImeiCode;
      this.isChangeProduct = true;
    },
    onClickCancel() {
      history.back();
    },
    onChangeProduct(product) {
      const data = {
        storeId: this.mainModel.storeId,
        product: product,
      };
      EventBus.$emit('popup-product-common', data);
    },
    getActiveStatus() {
      const listStatus = {
        [this.optionValue.new]: [
          WARRANTY_BILL_STATUS.NEW,
          WARRANTY_BILL_STATUS.PROCESSING,
          WARRANTY_BILL_STATUS.DONE,
        ],
        [this.optionValue.processing]: [
          WARRANTY_BILL_STATUS.PROCESSING,
          WARRANTY_BILL_STATUS.DONE,
        ],
        [this.optionValue.done]: [WARRANTY_BILL_STATUS.DONE],
      };
      this.listStatus = listStatus[this.mainModel.warrantyStatus];

      return listStatus;
    },
    onSelectedTransfer(option) {
      this.mainModel.transferAccountName = option.item.name;
      this.mainModel.transferAccountCode = option.item.code;
      this.mainModel.transferAccountId = option.item.id;
    },
    onInputChangeTransfer(text) {
      const filteredData = this.transferOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredTransferOptions = [...filteredData];
    },
    onSelectCash(option) {
      this.mainModel.cashAccountName = option.item.name;
      this.mainModel.cashAccountCode = option.item.code;
      this.mainModel.cashAccountId = option.item.id;
    },
    onChangeCash(text) {
      const filteredData = this.cashAccOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCashAccOptions = [...filteredData];
    },
    setEmployeeInfo() {
      const employeeInfo = getData('employee_info');
      if (employeeInfo) {
        const tmpInfo = JSON.parse(employeeInfo);
        this.mainModel.saleId = tmpInfo.id;
        this.mainModel.employeeName = tmpInfo.fullName;
      }
    },
    mapUpdateData(data) {
      this.mainModel.storeName = data.storeName;
      this.mainModel.storeId = data.storeId;
      this.mainModel.saleId = data.saleId;
      this.mainModel.employeeName = data.saleName;
      this.filteredStoreOptions = [];
      this.mainModel.warrantyNote = data.warrantyNote;
      this.mainModel.internalNote = data.saleNote;
      // payment info
      const payments = data.payments;
      this.mainModel.paymentInfo.cashAmount = data.cashAmount;
      this.mainModel.paymentInfo.cashAccountId = data.cashAccountId;
      this.mainModel.paymentInfo.cashAccountantName = data.cashAccName;
      this.mainModel.paymentInfo.cashAccountCode = data.cashAccountCode;
      this.mainModel.paymentInfo.transferAmount = data.transferAmount;
      this.mainModel.paymentInfo.transferAccountantId = data.transferAccountId;
      this.mainModel.paymentInfo.transferAccountantName = data.transferAccName;
      this.mainModel.paymentInfo.transferAccountCode = data.transferAccountCode;
      this.mainModel.paymentInfo.transferReferenceCode =
        data.transferReferenceCode;
      this.mainModel.paymentInfo.creditAmount = data.creditAmount;
      this.mainModel.paymentInfo.creditAccountantId = data.creditAccountId;
      this.mainModel.paymentInfo.creditAccountCode = data.creditAccountCode;
      this.mainModel.paymentInfo.creditAccountantName = data.creditAccName;
      this.mainModel.paymentInfo.creditCardNo = data.creditCode;
      this.mainModel.paymentInfo.creditCardCode = data.creditCardNo;
      this.mainModel.paymentInfo.creditCardFee = data.otherFees;
      this.mainModel.paymentInfo.payCreditFeeType = data.payCreditFeeType;
      this.mainModel.paymentInfo.creditFeeAccountantId = data.creditFeeAcountId;
      this.mainModel.paymentInfo.creditFeeAcountCode = data.creditFeeAcountCode;
      this.mainModel.paymentInfo.creditFeeAccountantName =
        data.creditFeeAccName;
      this.mainModel.paymentInfo.installedMoneyAmount =
        data.installedMoneyAmount;
      this.mainModel.paymentInfo.installMoneyAccountId =
        data.installMoneyAccountId;
      this.mainModel.paymentInfo.installmentAccountName =
        data.installmentAccName;

      this.mainModel.installments = this.mainModel.paymentInfo.installments =
        payments.filter(
          (item) => item.paymentType === PAYMENT_TYPE.INSTALLMENT,
        );
      this.mainModel.credits = this.mainModel.credits = payments.filter(
        (item) => item.paymentType === PAYMENT_TYPE.CREDIT,
      );

      this.mainModel.transfer = this.mainModel.transfer = payments.filter(
        (item) => item.paymentType === PAYMENT_TYPE.TRANSFER,
      );

      this.mainModel.cash = this.mainModel.cash = payments.filter(
        (item) => item.paymentType === PAYMENT_TYPE.CASH,
      );
      this.forceRerender();
    },
    mapBillItems(data) {
      this.listBillItem = [];
      const filteredBillItems = data.billItems.filter(
        (billItem) => billItem.billItemType !== BILL_ITEM_TYPE.PRODUCT_BONUS,
      );
      for (const billItem of filteredBillItems) {
        const { productType } = billItem;
        // neu san pham cua hoa don la san pham chinh hoac imei
        if (
          (productType === PRODUCT_TYPE.PRODUCT ||
            productType === PRODUCT_TYPE.PRODUCT_IMEI) &&
          this.action !== 're-create'
        ) {
          const product = {
            stt: 0,
            id: billItem.id,
            billItemId: billItem.id,
            productId: billItem.productId,
            productName: billItem.productName,
            productWebCategory: billItem.productWebCategory,
            productNameVat: billItem.productNameVat,
            code: billItem.productCode,
            productPrice: billItem.productPrice,
            productType: BILL_ITEM_TYPE.PRODUCT,
            returnPrice: 0,
            quantity: billItem.quantity,
            imeiCode: billItem.imeiCode ? billItem.imeiCode : '',
            totalPrice:
              billItem.quantity * billItem.productPrice -
              billItem.discountAmount,
            originalProductType: productType,
            originalQuantity: billItem.quantity,
            isGiftTaken: billItem.isGiftTaken,
            merchantId: billItem.merchantId || 1,
            warrantyReasonId: billItem.warrantyReasonId || null,
            warrantyReasonName: billItem.warrantyReasonName || '',
            belongToProductId: billItem.belongBillDetailId || null,
            newProductId: billItem.newProductId,
            newProductName: billItem.newProductName,
            newProductCode: billItem.newProductCode,
            newProductPrice: billItem.newProductPrice,
            newQuantity: billItem.newQuantity,
            newTotalPrice: billItem.newTotalPrice,
            newImeiCode: billItem.newImeiCode,
            isLostProduct: billItem.isLostProduct,
            externalImeiNoOldBill: billItem.externalImeiNo || '',
            externalImeiNo:
              this.action === 'update' ? billItem.externalImeiNo : '',
            allowViewFullExternalImeiNo:
              billItem.allowViewFullExternalImeiNo || false,
          };
          this.listBillItem.push(product);
        }
        // neu la san pham com bo
        if (productType === PRODUCT_TYPE.PRODUCT_COMBO) {
          billItem.listProductInCombo.map((element) => {
            const product = {
              stt: 0,
              billItemId: billItem.id,
              id: billItem.id,
              productId: element.productId,
              productName: element.productName,
              productWebCategory: element.productWebCategory,
              productNameVat: element.productNameVat,
              code: element.productCode,
              productPrice: element.sellingPrice,
              returnPrice: element.repurchasePrice,
              productType: BILL_ITEM_TYPE.PRODUCT,
              originalProductType: productType,
              originalQuantity: element.quantity,
              quantity: element.quantity,
              imeiCode: element.imeiCode ? element.imeiCode : '',
              totalPrice:
                element.quantity * element.sellingPrice -
                (element.discountAmount || 0),
              isGiftTaken: billItem.isGiftTaken,
              merchantId: billItem.merchantId || 1,
              warrantyReasonId: billItem.warrantyReasonId || null,
              warrantyReasonName: billItem.warrantyReasonName || '',
              belongToProductId: billItem.belongBillDetailId || null,
              newProductId: billItem.newProductId,
              newProductName: billItem.newProductName,
              newProductCode: billItem.newProductCode,
              newProductPrice: billItem.newProductPrice,
              newQuantity: billItem.newQuantity,
              newTotalPrice: billItem.newTotalPrice,
              isLostProduct: billItem.isLostProduct,
              externalImeiNoOldBill: billItem.externalImeiNo || '',
              externalImeiNo: '',
            };
            this.listBillItem.push(product);
          });
        }

        if (this.action === 're-create') {
          if (billItem.newProductId) {
            const product = {
              stt: 0,
              id: billItem.id,
              billItemId: billItem.id,
              productId: billItem.newProductId,
              productName: billItem.newProductName,
              productWebCategory: billItem.productWebCategory,
              productNameVat: billItem.productNameVat,
              code: billItem.newProductCode,
              productPrice: billItem.newProductPrice,
              productType: BILL_ITEM_TYPE.PRODUCT,
              returnPrice: 0,
              quantity: billItem.newQuantity,
              imeiCode: billItem.newImeiCode,
              totalPrice: billItem.newTotalPrice,
              originalProductType: productType,
              originalQuantity: billItem.newQuantity,
              isGiftTaken: billItem.isGiftTaken,
              merchantId: billItem.merchantId || 1,
              warrantyReasonId: null,
              warrantyReasonName: '',
              belongToProductId: null,
              newProductId: null,
              newProductName: '',
              newProductCode: '',
              newProductPrice: 0,
              newQuantity: 0,
              newTotalPrice: 0,
              newImeiCode: '',
              isLostProduct: false,
              externalImeiNoOldBill: billItem.externalImeiNo || '',
              externalImeiNo: '',
            };
            this.listBillItem.push(product);
          }
        }
      }
      this.listBillItem = this.formatBillItems();
    },
    fetchBillById: function () {
      if (!this.billNumber) return;
      const isCurrentProduct = this.action === 'create';
      const params = {
        isCurrentProduct: !!isCurrentProduct,
      };
      ApiService.query(`bills/${this.billNumber}`, { params }).then(
        (response) => {
          const { data } = response.data;
          // bill data
          this.billId = data.id;
          this.mainModel.customerName = data.customerName;
          this.mainModel.customerPhone = data.customerPhone;
          this.mainModel.customerAddress = data.customerBillAddress;
          this.mainModel.customerId = data.customerId;
          this.mainModel.warrantyStatus =
            data.status || WARRANTY_BILL_STATUS.NEW;
          this.checkWarrantyStatus = data.status || WARRANTY_BILL_STATUS.NEW;
          if (this.action === 're-create') {
            this.mainModel.warrantyStatus = WARRANTY_BILL_STATUS.NEW;
            this.checkWarrantyStatus = WARRANTY_BILL_STATUS.NEW;
          }

          this.mainModel.orderId = data.orderId;
          this.mainModel.billType = data.type;
          const isReCreateBillDoneQuery =
            this.$route.query.isReCreateBillDone === true ||
            this.$route.query.isReCreateBillDone === 'true';

          this.mainModel.isReCreateBillDone =
            isReCreateBillDoneQuery || data.isReCreateBillDone || false;

          this.warrantyErrorBy = data.warrantyErrorBy;
          this.mainModel.relateBill = { ...data.relateBill };
          if (this.action === 'update') {
            this.mapUpdateData(data);
          }
          //  bill items data
          if (data.billItems.length) {
            this.mapBillItems(data);
          }
          this.getActiveStatus();
        },
      );
    },
    formatBillItems() {
      const result = [];
      this.listBillItem.forEach((item, index) => {
        item.stt = index + 1;
        result.push(item);
      });

      return result;
    },
    deleteBillItem(deleteItem) {
      const removeIdex = this.listBillItem.findIndex(
        (obj) =>
          obj.id == deleteItem.id && obj.productId == deleteItem.productId,
      );
      this.listBillItem.splice(removeIdex, 1);
      this.listBillItem.forEach((_, index) => {
        this.listBillItem[index].stt = index + 1;
      });
    },
    onInputWarrantyReason(data) {
      this.listBillItem.map((item) => {
        if (item.id === data.productItem.id) {
          item.warrantyReasonName = data.text;
        }
      });
    },
    onSelectWarranty(data) {
      this.listBillItem.map((item) => {
        if (item.id === data.productItem.id) {
          item.warrantyReasonId = data.warrantyReasonId;
          item.warrantyReasonName = data.warrantyReasonName;
        }
      });
    },
    createWarrantyBill: function () {
      const listPro = this.listBillItem.map((element) => {
        return {
          id: element.id,
          billItemId: element.billItemId,
          productId: element.productId,
          productName: element.productName,
          productPrice: element.productPrice
            ? unMaskPrice(element.productPrice)
            : 0,
          quantity: element.quantity,
          discountAmount: element.discount ? unMaskPrice(element.discount) : 0,
          imeiCode: element.imeiCode,
          productType: element.productType,
          originalProductType: element.originalProductType,
          originalQuantity: element.originalQuantity,
          repurchasePrice: element.returnPrice
            ? unMaskPrice(element.returnPrice)
            : 0,
          isGiftTaken: element.isGiftTaken,
          note: '',
          merchantId: element.merchantId || 1,
          warrantyReasonId: element.warrantyReasonId,
          warrantyReasonName: element.warrantyReasonName,
          belongBillItemId: element.belongToProductId || null,
          isLostProduct: element.isLostProduct,
          externalImeiNo: element.externalImeiNo || '',
        };
      });
      const data = {
        customerMobile: this.mainModel.customerPhone,
        customerName: this.mainModel.customerName,
        customerAddress: this.mainModel.customerAddress,
        customerId: this.mainModel.customerId,
        status: this.mainModel.warrantyStatus,
        customerBankName: this.mainModel.customerBankName,
        customerTransferNo: this.mainModel.customerTransferNo,
        storeId: this.mainModel.storeId,
        warrantyNote: this.mainModel.warrantyNote,
        saleId: this.mainModel.saleId,
        saleNote: this.mainModel.internalNote,
        cashId: this.mainModel.cashId,
        cashAccountId: this.mainModel.cashAccountId,
        cashAccountName: this.mainModel.cashAccountName,
        cashAccountCode: this.mainModel.cashAccountCode,
        cashAmount: this.mainModel.cashAmount
          ? unMaskPrice(this.mainModel.cashAmount)
          : 0,
        transferAccountId: this.mainModel.transferAccountId,
        transferAccountName: this.mainModel.transferAccountName,
        transferAccountCode: this.mainModel.transferAccountCode,
        transferAmount: this.mainModel.transferAmount
          ? unMaskPrice(this.mainModel.transferAmount)
          : 0,
        employeeName: this.mainModel.employeeName,
        warrantyErrorBy: Number(this.warrantyErrorBy) || WARRANTY_ERROR_BY.BILL,
        listBillItem: listPro,
        cash: this.mainModel.cash,
        transfer: this.mainModel.transfer,
        credits: this.mainModel.credits,
        isReCreateBillDone: this.mainModel.isReCreateBillDone,
      };

      if (this.isNew === true) {
        this.isNew = false;
        ApiService.post(`/bills/warranty-bill/${this.billId}`, data)
          .then((response) => {
            const { status, message } = response.data;
            if (status === 1) {
              makeToastSuccess(message);
              this.$router.push({
                path: '/warranty-bill',
              });
            } else {
              makeToastFaile(message);
              this.isNew = true;
            }
          })
          .catch(({ response }) => {
            const { status, message } = response.data;
            if (status === 0) {
              makeToastFaile(message);
              this.isNew = true;
            }
          });
      }
    },
    updateWarrantyBill: function () {
      if (!this.isNew) return;
      this.isNew = false;
      console.log('this.listBillItem::: ', this.listBillItem);
      const listPro = this.listBillItem.map((element) => {
        return {
          id: element.id,
          billItemId: element.id,
          productId: element.productId,
          productName: element.productName,
          productPrice: element.productPrice
            ? unMaskPrice(element.productPrice)
            : 0,
          quantity: element.quantity,
          discountAmount: element.discount ? unMaskPrice(element.discount) : 0,
          imeiCode: element.imeiCode,
          productType: element.productType,
          repurchasePrice: element.returnPrice
            ? unMaskPrice(element.returnPrice)
            : 0,
          isGiftTaken: element.isGiftTaken,
          note: '',
          merchantId: element.merchantId || 1,
          warrantyReasonId: element.warrantyReasonId,
          warrantyReasonName: element.warrantyReasonName,
          belongBillItemId: element.belongToProductId || null,
          newProductId: element.newProductId,
          newProductType: BILL_ITEM_TYPE.RETURN_WARRANTY,
          newProductName: element.newProductName,
          newProductCode: element.newProductCode,
          newProductPrice: element.newProductPrice,
          newTotalPrice: element.newTotalPrice,
          newQuantity: element.newQuantity,
          newImeiCode: element.newImeiCode,
          isLostProduct: element.isLostProduct,
          externalImeiNo: element.externalImeiNo,
        };
      });

      const data = {
        storeId: this.mainModel.storeId,
        customerId: this.mainModel.customerId,
        customerName: this.mainModel.customerName,
        customerMobile: this.mainModel.customerPhone,
        customerAddress: this.mainModel.customerAddress,
        warrantyNote: this.mainModel.warrantyNote,
        saleNote: this.mainModel.internalNote,
        saleId: this.mainModel.saleId,
        transferAmount: this.mainModel.transferAmount
          ? unMaskPrice(this.mainModel.transferAmount)
          : 0,
        transferId: this.mainModel.transferId,
        transferAccountId: this.mainModel.transferAccountId,
        transferAccountName: this.mainModel.transferAccountName,
        transferAccountCode: this.mainModel.transferAccountCode,
        customerTransferNo: this.mainModel.customerTransferNo,
        customerBankName: this.mainModel.customerBankName,
        cashAmount: this.mainModel.cashAmount
          ? unMaskPrice(this.mainModel.cashAmount)
          : 0,
        cashId: this.mainModel.cashId,
        cashAccountId: this.mainModel.paymentInfo.cashAccountId,
        cashAccountName: this.mainModel.paymentInfo.cashAccountName,
        cashAccountCode: this.mainModel.paymentInfo.cashAccountCode,
        status: this.mainModel.warrantyStatus,
        cash: this.mainModel.cash,
        transfer: this.mainModel.transfer,
        credits: this.mainModel.credits,
        listBillItem: listPro,
        warrantyErrorBy: this.warrantyErrorBy || WARRANTY_ERROR_BY.BILL,
      };
      if (this.isChangeProduct) {
        data['customerOTP'] = this.customerOTP;
      }
      // if (data) {
      //   return;
      // }
      ApiService.put(`/bills/warranty-bill/${this.billNumber}`, data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.$router.push({
              path: '/warranty-bill',
            });
          } else {
            makeToastFaile(message);
            this.isNew = true;
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
            this.isNew = true;
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
            this.isNew = true;
          }
        });
    },
    validateWarrantyReason() {
      let result = { status: true, message: '' };
      this.listBillItem.map((item) => {
        if (!item.warrantyReasonName) {
          result.status = false;
          result.message = `Vui lòng nhập lý do bảo hành cho sản phẩm ${item.productName}`;
          return;
        }
        const isValidReason = this.warrantyReasons.find(
          (reason) => reason.name === item.warrantyReasonName,
        );
        if (!isValidReason) {
          result.status = false;
          result.message = `Lý do ${item.warrantyReasonName} không hợp lệ`;
          return;
        }
      });

      return result;
    },
    validatePayment() {
      if (unMaskPrice(this.mainModel.cashAmount) > 0) {
        if (
          !isEmptyAccountingAccount(
            this.mainModel.cashAmount,
            this.mainModel.cashAccountName,
          )
        ) {
          return false;
        }
      }
      if (unMaskPrice(this.mainModel.transferAmount) > 0) {
        if (
          !isEmptyAccountingAccount(
            this.mainModel.transferAmount,
            this.mainModel.transferAccountName,
          )
        ) {
          return false;
        }
      }

      return true;
    },
    debounceSubmit: debounce(async function () {
      await this.onSubmit();
    }, TIME_TRIGGER),
    validate() {
      const result = {
        valid: true,
        message: '',
      };
      //  validate list product
      if (!this.listBillItem.length) {
        result.message = 'Vui lòng nhập sản phẩm';
        result.valid = false;
      }
      if (!this.mainModel.customerPhone) {
        result.message = 'Vui lòng nhập số điện thoại';
        result.valid = false;
      }
      if (!this.mainModel.employeeName) {
        result.message = 'Vui lòng nhập nhân viên bảo hành';
        result.valid = false;
      }
      if (!this.mainModel.customerName) {
        result.message = 'Vui lòng nhập họ tên khách hàng';
        result.valid = false;
      }
      if (!this.mainModel.storeId) {
        result.message = 'Vui lòng nhập cửa hàng';
        result.valid = false;
      }

      /** Kiểm tra OTP nếu thay đổi product & hóa đơn được tạo lại từ hóa đơn đã trả bảo hành & có quyền */
      if (
        this.isChangeProduct &&
        !this.customerOTP &&
        !(
          this.mainModel.isReCreateBillDone &&
          this.checkPermission('WARRANTY_BILL_DONE_RECREATE')
        )
      ) {
        result.message = 'Vui lòng nhập OTP';
        result.valid = false;
      }
      //  validate accounting account
      const validatePayment = this.validatePayment();
      if (!validatePayment) {
        result.message = 'Dữ liệu không hợp lệ';
        result.valid = false;
      }
      //  validate warranty reason
      const validateWarrantyReason = this.validateWarrantyReason();
      if (!validateWarrantyReason.status) {
        result.message = validateWarrantyReason.message;
        result.valid = false;
      }

      return result;
    },
    async onSubmit() {
      this.onLoading = true;
      const validationResult = this.validate();
      if (!validationResult.valid) {
        makeToastFaile(validationResult.message);

        this.onLoading = false;
        return;
      }

      // CASE: đơn đã trả bảo hành tạo lại
      if (this.billNumberRemove && this.mainModel.isReCreateBillDone) {
        const { status } = await this.deleteBillWarranty(this.billNumberRemove);

        if (status === STATUS_CODE.SUCCESS) {
          return this.createWarrantyBill();
        }

        return;
      }

      if (this.action === 'update') {
        this.updateWarrantyBill();
      } else {
        this.createWarrantyBill();
      }
      this.onLoading = false;
    },
    async deleteBillWarranty(billNumber) {
      try {
        const data = await ApiService.delete(
          '/bills/warranty' + '/' + billNumber,
        );

        const { status, message } = data.data;
        return { status, message };
      } catch (error) {
        return {
          status: STATUS_CODE.ERROR,
          message: 'Lỗi khi xóa hóa đơn',
        };
      }
    },
    fetchWarrantyReasons: function () {
      const param = {
        page: 1,
        pageSize: 10000,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('warranty-reason-config', paramQuery).then(
        (response) => {
          const { result } = response.data.data;
          result.forEach((reason) => {
            const item = {
              id: reason.id,
              name: reason.name,
              internalCateId: reason.internalCateId,
            };
            this.warrantyReasons.push(item);
          });
        },
      );
    },
    checkOTP() {
      this.onSubmiting = true;
      const otpPayload = {
        customerId: this.mainModel.customerId,
        otpCode: this.customerOTP,
      };
      ApiService.post('customerOTP/check-generate-otp', otpPayload)
        .then(({ data }) => {
          this.onSubmiting = false;
          if (data.status === 1) {
            makeToastSuccess(data.message);
          } else {
            makeToastFaile(data.message);
            this.stateOTP = false;
          }
        })
        .catch(({ response }) => {
          this.onSubmiting = false;
          makeToastFaile(response.data.message);
        });
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>

<style lang="scss" scoped>
@import '@/assets/sass/pages/wizard/wizard-4.scss';
@import 'src/assets/sass/components/_variables.bootstrap.scss';
@import '~bootstrap-vue/dist/bootstrap-vue.css';

.upsert-warranty-bill ::v-deep {
  // common
  $small-clumn-width: 7%;
  $tiny-clumn-width: 5%;
  $brown-color: brown;
  .card-footer {
    padding: 20px 0;
    border-top: none;
  }

  .card-body {
    padding: 0;
    border-bottom: none;
  }

  .column-quantity {
    width: $small-clumn-width;
  }

  .column-import-stock {
    width: $small-clumn-width;
  }

  .column-swap-product {
    width: $small-clumn-width;
  }

  .form-group label {
    font-weight: $font-weight-bold;
  }

  .select-style {
    border-radius: 0.28rem;
    box-shadow: none;
    border: 1px solid #ced4da;
  }

  .input-style {
    border: 1px solid #ced4da;
  }

  .header-table {
    color: rgb(24, 28, 50);
  }

  .text-payment {
    font-size: $h5-font-size;
    color: $brown-color;
    font-weight: $font-weight-bold;
  }

  .text-list-product {
    font-size: $h5-font-size;
    color: $brown-color;
    font-weight: $font-weight-bold;
  }
  .main-info,
  .attach-image {
    padding-top: 20px;
  }
}
</style>
